import { defineComponent, onBeforeUnmount, onMounted, ref } from 'vue';
import imagesLoaded from 'imagesloaded';
export default defineComponent({
    name: 'TopJobsColumnLayout',
    setup() {
        const columnElement = ref(null);
        const resizeGridItem = (item) => {
            const rowHeight = parseInt(getComputedStyle(columnElement.value).getPropertyValue('grid-auto-rows'));
            const rowGap = parseInt(getComputedStyle(columnElement.value).getPropertyValue('grid-row-gap'));
            const itemPadding = parseInt(getComputedStyle(item).getPropertyValue('padding-bottom'));
            const elementHeight = item.children[0].getBoundingClientRect().height;
            const rowSpan = Math.ceil((elementHeight + rowGap + itemPadding) / (rowHeight + rowGap));
            item.style.gridRowEnd = 'span ' + rowSpan;
        };
        const resizeAllGridItems = () => {
            if (!columnElement.value)
                return;
            Array.from(columnElement.value.children).forEach((item) => {
                resizeGridItem(item);
            });
        };
        onMounted(() => {
            window.addEventListener('resize', resizeAllGridItems);
            setTimeout(() => resizeAllGridItems(), 500);
            resizeAllGridItems();
            if (!columnElement.value)
                return;
            Array.from(columnElement.value.children).forEach((item) => {
                imagesLoaded(item, () => resizeGridItem(item));
            });
        });
        onBeforeUnmount(() => {
            window.removeEventListener('resize', resizeAllGridItems);
        });
        return { columnElement };
    },
});
